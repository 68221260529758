import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setFilterValue } from "../features/searchFilter/searchFilterSlice";

const Middlebar = () => {
  const dispatch = useDispatch();
  const [filterVal, setFilterVal] = useState("");

  const handleFilter = (e) => {
    setFilterVal(e.target.value);
    dispatch(setFilterValue(e.target.value));
  };

  return (
    <div className="flex items-center justify-between mb-12">
      <h4 className="mt-2 text-xl font-bold">Book List</h4>

      <div className="flex items-center space-x-4">
        <button
          className={`lws-filter-btn ${
            filterVal !== "featured" && "active-filter"
          }`}
          value=""
          onClick={handleFilter}
        >
          All
        </button>
        <button
          className={`lws-filter-btn ${
            filterVal === "featured" && "active-filter"
          }`}
          value="featured"
          onClick={handleFilter}
        >
          Featured
        </button>
      </div>
    </div>
  );
};

export default Middlebar;
