import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useGetBooksQuery } from "../../features/api/apiSlice";
import BookItem from "./BookItem";

const BookList = () => {
  const {
    data: books,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetBooksQuery();
  const { searchValue, filterValue } = useSelector(
    (state) => state.searchFilter
  );

  const [finalBooks, setFinalBooks] = useState([]);
  const [featuredBooks, setFeaturedBooks] = useState([]);

  useEffect(() => {
    if (isSuccess) setFinalBooks(books);
  }, [books, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      if (filterValue === "featured") {
        setFinalBooks(books.filter((book) => book.featured === true));
        setFeaturedBooks(books.filter((book) => book.featured === true));
      } else {
        setFinalBooks(books);
        setFeaturedBooks(books);
      }
    }
  }, [books, filterValue, isSuccess, searchValue]);

  // make sure to search in both all and featured books
  useEffect(() => {
    if (isSuccess) {
      if (searchValue !== "") {
        setFinalBooks(
          featuredBooks.filter((book) =>
            book.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setFinalBooks(featuredBooks);
      }
    }
  }, [featuredBooks, isSuccess, searchValue]);

  return (
    <div className="space-y-6 md:space-y-0 md:grid grid-cols-1 lg:grid-cols-3 gap-6">
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {isSuccess &&
        finalBooks.map((book) => <BookItem key={book.id} book={book} />)}
    </div>
  );
};

export default BookList;
