import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from "../features/api/apiSlice";

import searchFilterReducer from "../features/searchFilter/searchFilterSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    searchFilter: searchFilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
