import React from "react";
import BookList from "../components/BookComponents/BookList";
import Middlebar from "../components/Middlebar";

const Home = () => {
  return (
    <main className="py-12 px-6 2xl:px-6 container">
      <div className="order-2 xl:-order-1">
        <Middlebar />
        <BookList />
      </div>
    </main>
  );
};

export default Home;
