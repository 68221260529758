import React from 'react';
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Header from "./components/Header";
import AddBook from "./pages/AddBook";
import EditBook from "./pages/EditBook";

import './App.css';

function App() {
  return (
    <Router>
      <Toaster />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book/add" element={<AddBook />} />
        <Route path="/book/edit/:bookID" element={<EditBook />} />
      </Routes>
    </Router>
  );
}

export default App;
