import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";

import AddEditForm from "../components/AddEditForm";
import { useGetBookQuery, useEditBookMutation } from "../features/api/apiSlice";

const EditBook = () => {
  const navigate = useNavigate();
  const { bookID } = useParams();

  const { data: book, isSuccess } = useGetBookQuery(bookID);
  const [editBook, { isSuccess: isEditSuccess }] = useEditBookMutation();

  const [bookData, setBookData] = useState({
    name: "",
    author: "",
    thumbnail: "",
    price: 0,
    rating: 1,
    featured: false,
  });

  const handleEditBook = (e) => {
    e.preventDefault();
    editBook(bookData);
  };

  useEffect(() => {
    if (isSuccess) {
      setBookData(book);
    }
  }, [isSuccess, book]);

  useEffect(() => {
    if (isEditSuccess) {
      toast.success("Book edited successfully", {
        duration: 3000,
        style: {
          border: "2px solid #5850EC",
        },
        iconTheme: {
          primary: "#5850EC",
        },
      });
      navigate("/");
    }
  }, [isEditSuccess, navigate]);

  return (
    <main className="py-6 2xl:px-6">
      <div className="container">
        <div className="p-8 overflow-hidden bg-white shadow-cardShadow rounded-md max-w-xl mx-auto">
          <h4 className="mb-8 text-xl font-bold text-center">Add New Book</h4>
          <AddEditForm
            bookData={bookData}
            setBookData={setBookData}
            isEditing={true}
            handleFormSubmit={handleEditBook}
          />
        </div>
      </div>
    </main>
  );
};

export default EditBook;
