import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddEditForm from "../components/AddEditForm";

import { useAddBookMutation } from "../features/api/apiSlice";

const AddBook = () => {
  const navigate = useNavigate();
  const [book, setBook] = useState({
    name: "",
    author: "",
    thumbnail: "",
    price: 0,
    rating: 1,
    featured: false,
  });

  const [addBook, { isSuccess }] = useAddBookMutation();

  const handleAddBook = (e) => {
    e.preventDefault();
    addBook(book);
  };

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  return (
    <main className="py-6 2xl:px-6">
      <div className="container">
        <div className="p-8 overflow-hidden bg-white shadow-cardShadow rounded-md max-w-xl mx-auto">
          <h4 className="mb-8 text-xl font-bold text-center">Add New Book</h4>
          <AddEditForm
            bookData={book}
            setBookData={setBook}
            isEditing={false}
            handleFormSubmit={handleAddBook}
          />
        </div>
      </div>
    </main>
  );
};

export default AddBook;
