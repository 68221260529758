import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchValue: "",
    filterValue: ""
};

const searchFilterSlice = createSlice({
    name: "searchFilter",
    initialState,
    reducers: {
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setFilterValue: (state, action) => {
            state.filterValue = action.payload;
        }
    }
});

export default searchFilterSlice.reducer;
export const { setSearchValue, setFilterValue } = searchFilterSlice.actions;
